<template>
  <PVDialog :visible="visible" :modal="true" :closable="false">
    <template #header>
      <slot name="toolbar">
        <div class="bg-primary text-primary-contrast flex pvflex flex-row items-center justify-between w-full" :style="'height:4rem; width:'+width">
          <div style="width: 48px"></div>
          <div class="text-h6">
            {{ header ? header : title }}
          </div>
          <div style="width: 48px">
            <PVButton raised icon="material-icons close" class="uppercase nowrap" severity="secondary" @click.stop="close"/>
          </div>
        </div>
      </slot>
    </template>
    <slot>Put your content here</slot>
  </PVDialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ResponsiveDialog',
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      default: undefined,
    },
    header: {
      type: String,
      default: undefined,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    visible:{
      type:Boolean,
      default:false,
      required:true,
    },
    width:{
      type: String,
      default:undefined,
    },
  },
  emits:['close', 'update:visible'],
  methods:{
    close():void {
      this.$emit('update:visible', false);
      this.$emit('close');
    }
  }
});
</script>

<style scoped>

</style>
